import { Box } from '@mui/material';
import { Form, Formik } from 'formik';
import React from 'react';
import { useNavigate } from 'react-router';

import Button from 'components/UI/Button';
import LoadingButton from 'components/UI/LoadingButton';
import InputField from 'components/UI/form/InputField';
import { ROUTES } from 'constants/routes';
import useTranslate from 'hooks/useTranslate';
import { HttpError } from 'models/http-interface';
import { useAppDispatch, useAppSelector } from 'redux/redux';
import { showError, showSuccess } from 'utils/toast-alerts';
import { ChangePasswordOnlyEmailSchema } from 'utils/validation-schema';
import { ChangePasswordEmailData } from 'models/user-interface';
import { companyDetails, selectLoading } from 'redux/auth/selector';
import { verifyEmailForPasswordChange } from 'redux/auth/thunk';
import { formatError } from 'utils/http-error-formatter';

const initialValues = {
  email: '',
};

/**
 * @desc: reset password form
 * @returns
 */
const ForgotPassword: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const companyData = useAppSelector(companyDetails);

  const { parseUI } = useTranslate();
  const loading = useAppSelector(selectLoading);

  /**
   * @desc: Handle form submission
   * @param values
   */
  const handleSubmit = async (values: ChangePasswordEmailData) => {
    try {
      const response = await dispatch(
        verifyEmailForPasswordChange({ ...values })
      ).unwrap();

      /** to display toast, with success message */
      if (response.message) {
        showSuccess(response.message);
        navigate(`/`);
      }
    } catch (error) {
      const errorMsg = formatError((error as HttpError).error);
      showError(errorMsg);
      navigate(`/`);
    }
  };

  /**
   * @desc: on click of cancel button
   */
  const onCancelClick = () => {
    navigate(`/${ROUTES.LOGIN}`);
  };

  return (
    <Box
      className="loginWrapper"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      style={{
        paddingTop: 30,
        paddingBottom: 37,
        paddingLeft: 15,
        paddingRight: 15,
      }}
    >
      <img src={companyData?.logo} width={160} alt={companyData?.name} />
      <h1 className="main-heading">{parseUI('RESET_PASSWORD')}</h1>
      <Formik
        initialValues={initialValues}
        validationSchema={ChangePasswordOnlyEmailSchema}
        onSubmit={handleSubmit}
      >
        {({ isValid, dirty }) => (
          <Form className="login-outer change-pass-form">
            <InputField name="email" type="text" label="EMAIL" required />
            <div className="btn-end">
              <LoadingButton
                type="submit"
                label="SUBMIT"
                loader={loading}
                className="loading-button"
                disabled={!isValid || !dirty || loading === true}
              />

              <Button type="button" onClick={onCancelClick} label="CANCEL" />
            </div>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default ForgotPassword;
