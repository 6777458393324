import axios, { AxiosError, AxiosInstance } from 'axios';
import * as jwt_decode from 'jwt-decode';

import { AxiosExtendsRequest } from 'models/http-interface';
import { updateAccessTokenAsync } from 'redux/auth/thunk';
import { store } from 'redux/store';
import { HTTP_CODE } from 'utils/http-code';
import { removeUser } from 'utils/remove-user';
import { API_TIMEOUTS } from '../constants/axios-timeout';
import httpStatusCode from 'constants/http-status-code';

const { DEFAULT } = API_TIMEOUTS;

const axiosInstance: AxiosInstance = axios.create({
  baseURL: 'https://api.smile-garantie.de',
  timeout: DEFAULT,
});

/* The `axiosInstance.interceptors.request.use()` function is adding a request interceptor to the Axios
instance. This interceptor is used to modify the outgoing request before it is sent.. */
axiosInstance.interceptors.request.use(
  (req) => {
    const token = store.getState().auth.accessToken;

    if (token) {
      req.headers['authorization'] = `Bearer ${token}`;
    }
    req.params = {
      ...req.params,
    };

    return req;
  },
  (error: AxiosError) => {
    // Handle request error (optional)
    return Promise.reject(error.message);
  }
);

/* The `axiosInstance.interceptors.response.use()` function is adding a response interceptor to the
Axios instance. This interceptor is used to modify the response before it is returned to the calling
code. */
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error: AxiosError) => {
    let originalRequest: AxiosExtendsRequest = error?.config;

    if (error.response) {
      /* The code `if (error.response.status === 401) { removeUser(); }` is checking if the response
     status code is 401 (Unauthorized). If it is, it calls the `removeUser()` function, which is
     responsible for removing the user's authentication token or any other user-related data from
     the application. This is typically done to log the user out or revoke their access if their
     authentication token is no longer valid or expired. */
      if (error.response.status === httpStatusCode.UNAUTHORIZED) {
        removeUser();
        window.location.href = '/';
      }
      const refreshToken = store.getState().auth.refreshToken;
      const accessToken = store.getState().auth.accessToken;

      if (
        error.response.status === httpStatusCode.PRE_CONDITION_FAILED &&
        originalRequest &&
        !originalRequest?._retry &&
        refreshToken
      ) {
        /** decode the JWT access token to find companySlug */
        const decodedToken = jwt_decode.jwtDecode(accessToken);

        originalRequest._retry = true;

        const response = await store
          .dispatch(
            updateAccessTokenAsync({
              refreshToken,
              companySlug: (decodedToken as any).slug,
            })
          )
          .unwrap();

        if (response) {
          return axiosInstance(originalRequest);
        }
        return Promise.reject(error.response.data);
      }

      return Promise.reject(error.response.data);
    } else {
      /* The code `if (error.code === HTTP_CODE.ECONNABORTED) { return Promise.reject({ error:
      'Internal Server Error' }); }` is checking if the error code of the Axios error is equal to
      `HTTP_CODE.ECONNABORTED`. */
      if (error.code === HTTP_CODE.ECONNABORTED) {
        return Promise.reject({ error: 'Internal Server Error' });
      }
      return Promise.reject({ error: error.message });
    }
  }
);

export default axiosInstance;
