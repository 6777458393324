import { Box, Button, Modal, Typography } from "@mui/material";
import useTranslate from "hooks/useTranslate";
import { FC } from "react";

type ModalProp = {
  open: boolean;
  btnText: string;
  handleClose: () => void;
  onDeleteRetailer: () => void;
  text: string;
  subText: string;
};

/**
 * @desc: common modal component
 * @param param0
 * @returns
 */
const ModalComponent: FC<ModalProp> = ({
  open,
  btnText,
  handleClose,
  onDeleteRetailer,
  text,
  subText,
}) => {
  const { parseUI } = useTranslate();

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} className="retailer-modal">
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {text}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {subText}{" "}
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
          <Button variant="outlined" onClick={handleClose} sx={{ mr: 1 }}>
            {parseUI("CANCEL")}
          </Button>
          <Button variant="contained" onClick={onDeleteRetailer}>
            {btnText}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default ModalComponent;
