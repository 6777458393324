import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { formatError } from "utils/http-error-formatter";
import { showError } from "utils/toast-alerts";
import { HttpError } from "models/http-interface";
import initialState from "./initial-state";
import {
  createRetailerAsync,
  getRetailerListAsync,
  deleteRetailerAsync,
  updateRetailerAsync,
} from "./thunk";
import { Retailer } from "models/retailer-interface";

export const retailerSlice = createSlice({
  name: "retailers",
  initialState,
  reducers: {
    setRetailerData: (state, action: PayloadAction<Retailer>) => {
      state.retailerDetail = action.payload;
    },

    updateRetailersSortingKeyAndOrder: (
      state,
      action: PayloadAction<{
        sortingKeyOrder: {
          sortingKey: string | null;
          sortingOrder: number | null;
        };
      }>
    ) => {
      state.sortingKey = action.payload.sortingKeyOrder.sortingKey;
      state.sortingOrder = action.payload.sortingKeyOrder.sortingOrder;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createRetailerAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createRetailerAsync.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(createRetailerAsync.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getRetailerListAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getRetailerListAsync.fulfilled, (state, { payload }) => {
        state.retailersData = payload.data.list;
        state.count = payload.data.count;
        state.isLoading = false;
      })
      .addCase(getRetailerListAsync.rejected, (state, action) => {
        state.isLoading = false;
        const errorMsg = formatError((action.payload as HttpError).error);
        showError(errorMsg);
      })
      .addCase(deleteRetailerAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteRetailerAsync.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(deleteRetailerAsync.rejected, (state, action) => {
        const errorMsg = formatError((action.payload as HttpError).error);
        showError(errorMsg);
        state.isLoading = false;
      })
      .addCase(updateRetailerAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateRetailerAsync.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(updateRetailerAsync.rejected, (state, action) => {
        const errorMsg = formatError((action.payload as HttpError).error);
        showError(errorMsg);
        state.isLoading = false;
      });
  },
});

export const { setRetailerData, updateRetailersSortingKeyAndOrder } =
  retailerSlice.actions;

export default retailerSlice.reducer;
