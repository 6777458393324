export const USER = {
  LOGIN: '/api/v1/user/login',
  CHANGE_PASSWORD: '/api/v1/user/reset-password',
  UPDATE_ACCESS_TOKEN: '/api/v1/user/token',
  VERIFY_RESET_PASSWORD_TOKEN: '/api/v1/user/reset-password-token-verify',
};

export const RETAILERS = {
  RETAILERS: '/api/v1/retailer/list',
  RETAILER: '/api/v1/retailer',
};

export const COMPANY = {
  COMPANY: '/api/v1/company',
};
