import { Button as MuiButton } from '@mui/material';
import { ButtonProps } from '@mui/material/Button';
import React, { ReactNode } from 'react';

import useTranslate from 'hooks/useTranslate';

interface CustomButtonProps {
    label: string;
    color?: ButtonProps['color'];
    isDisabled?: boolean;
    icon?: boolean;
    onClick?: () => void;
    startIcon?: ReactNode;
    type?: 'button' | 'submit' | 'reset';
    variant?: 'contained' | 'outlined' | 'text';
}

const Button: React.FC<CustomButtonProps> = ({
    color = 'primary',
    isDisabled = false,
    onClick,
    label,
    startIcon,
    type = 'button',
    variant = 'contained',
    ...props
}) => {
    const { parseUI } = useTranslate();

    return (
        <MuiButton
            disabled={isDisabled}
            type={type}
            startIcon={startIcon}
            variant={variant}
            color={color}
            onClick={onClick}
            {...props}
        >
            {parseUI(label)}
        </MuiButton>
    );
};

export default Button;
