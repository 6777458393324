import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  createRetailer,
  deleteRetailer,
  getRetailersList,
  updateRetailer,
} from './api';
import { ListParams, UpdateRetailerThunk } from 'models/retailer-interface';

/**
 * @desc: to create a new retailer
 */
export const createRetailerAsync = createAsyncThunk(
  'retailer/create',
  async (
    { pestCompanyInfo }: { pestCompanyInfo: FormData },
    { rejectWithValue }
  ) => {
    try {
      const response = await createRetailer(pestCompanyInfo);

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

/**
 * @desc: to delete a retailer
 */
export const deleteRetailerAsync = createAsyncThunk(
  'retailer/delete',
  async ({ retailerId }: { retailerId: string }, { rejectWithValue }) => {
    try {
      const response = await deleteRetailer(retailerId);

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

/**
 * @desc: to get all the retailers list
 */
export const getRetailerListAsync = createAsyncThunk(
  'retailer/list',
  async (params: ListParams, { rejectWithValue }) => {
    try {
      const { data } = await getRetailersList(params);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

/**
 * @desc: to update a retailer
 */
export const updateRetailerAsync = createAsyncThunk(
  'retailer/update',
  async ({ retailerId, data }: UpdateRetailerThunk, { rejectWithValue }) => {
    try {
      const response = await updateRetailer(retailerId, data);

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
