import * as Yup from "yup";
import { ALPHA_NUMERIC, PASSWORD_REGEX, URL } from "./regex";
import config from "constants/config";
import fileFormats from "constants/file-formats";

const email = {
  email: Yup.string()
    .trim()
    .lowercase()
    .email("INVALID_EMAIL")
    .required("EMAIL_IS_REQUIRED"),
};

const password = {
  password: Yup.string()
    .trim()
    .matches(PASSWORD_REGEX, "PASSWORD_ERROR")
    .min(8, "PASSWORD_LENGTH")
    .required("PASSWORD_IS_REQUIRED"),
};

/* The code is defining a schema called `LoginSchema` using the Yup library. */
export const LoginSchema = Yup.object().shape({
  ...email,
  ...password,
});

/* The code is defining a schema called `ChangePasswordSchema` using the Yup library. This schema is
used to validate the input fields when changing a password. */
export const ChangePasswordSchema = Yup.object().shape({
  ...password,
  passwordConfirm: Yup.string()
    .oneOf([Yup.ref("password"), null], "PASSWORD_MUST_MATCH")
    .required("CONFIRM_PASSWORD_REQUIRED"),
});

/* The code is defining a schema called `ChangePasswordSchema` using the Yup library. This schema is
used to validate the input fields when changing a password. */
export const ChangePasswordOnlyEmailSchema = Yup.object().shape({
  ...email,
});

/** retailer name validation */
const retailerName = {
  name: Yup.string()
    .trim()
    .required("RETAILER_NAME_REQUIRED")
    .min(5, "MIN_LENGTH_5")
    .max(120, "MAX_LENGTH_60")
    .matches(ALPHA_NUMERIC, "ALPHANUMERIC_REQUIRED"),
};

/* The code is defining a schema called `AddRetailerSchema` using the Yup library. This schema is
used to validate the input fields when adding a retailer. */
export const AddRetailerSchema = Yup.object().shape({
  ...retailerName,
  // color: Yup.string().trim().required("COLOR_PICKER_REQUIRED"),
  place: Yup.string()
    .trim()
    .required("PLACE_REQUIRED")
    .max(50, "MAX_LENGTH_50"),
  logo: Yup.mixed()
    .required("RETAILER_LOGO_REQUIRED")
    .test(
      "fileFormat",
      "UNSUPPORTED_IMAGE_FORMAT",
      (value) =>
        value &&
        [fileFormats.JPEG, fileFormats.JPG, fileFormats.PNG].includes(
          (value as File).type
        )
    )
    .test(
      "fileSize",
      "FILE_SIZE_TOO_LARGE",
      (value) => value && (value as File).size <= config.MAX_IMAGE_SIZE_LOGO
    ),
});

/* The code is defining a schema called `fileOrUrlValidation` using the Yup library. This schema is
used to validate the input fields when editing a retailer's logo. */
export const fileOrUrlValidation = Yup.mixed()
  .required("RETAILER_LOGO_REQUIRED")
  .test("fileOrUrl", "The logo must be a file or a valid URL", (value) => {
    if (!value) {
      return true; /**  Passing validation if no file is selected (optional field) */
    }

    if (typeof value === "string") {
      /** Validate as URL */
      if (!URL.test(value)) {
        throw new Yup.ValidationError("LOGO_URL_VALID");
      }

      return true;
    } else if (value instanceof File) {
      /** Additional validation for file size and format */
      const isValidSize = value.size <= config.MAX_IMAGE_SIZE_LOGO;
      const isValidFormat = [
        fileFormats.JPEG,
        fileFormats.JPG,
        fileFormats.PNG,
      ].includes(value.type);

      if (!isValidSize) {
        throw new Yup.ValidationError("FILE_SIZE_TOO_LARGE");
      }
      if (!isValidFormat) {
        throw new Yup.ValidationError("UNSUPPORTED_IMAGE_FORMAT");
      }
      return true;
    }

    return false; /** Fails validation for any other case */
  });

/* The code is defining a schema called `fileValidation` using the Yup library. This schema is
used to validate the input fields when editing a retailer's logo. */
export const fileValidation = Yup.mixed()
  .test("file", "The logo must be a file", (value) => {
    if (!value) {
      return true; /**  Passing validation if no file is selected (optional field) */
    }
    if (value instanceof File) {
      /** Additional validation for file size and format */
      const isValidSize = value.size <= config.MAX_IMAGE_SIZE_LOGO;
      const isValidFormat = [
        fileFormats.JPEG,
        fileFormats.JPG,
        fileFormats.PNG,
      ].includes(value.type);

      if (!isValidSize) {
        throw new Yup.ValidationError("FILE_SIZE_TOO_LARGE");
      }
      if (!isValidFormat) {
        throw new Yup.ValidationError("UNSUPPORTED_IMAGE_FORMAT");
      }
      return true;
    }

    return false; /** Fails validation for any other case */
  })
  .required("RETAILER_LOGO_REQUIRED");

/* The code is defining a schema called `EditRetailerSchema` using the Yup library. This schema is
used to validate the input fields when adding a retailer. */
export const EditRetailerSchema = Yup.object().shape({
  ...retailerName,
  place: Yup.string()
    .trim()
    .required("PLACE_REQUIRED")
    .max(50, "MAX_LENGTH_50"),
  // color: Yup.string().trim().required("COLOR_PICKER_REQUIRED"),
  logo: Yup.mixed()
    .required("RETAILER_LOGO_REQUIRED")
    .test("isString", "INVALID_URL_FORMAT", (value) => {
      if (typeof value === "string") {
        return URL.test(value);
      }
      return true;
    })
    .test("fileFormat", "UNSUPPORTED_IMAGE_FORMAT", (value) => {
      if (typeof value !== "string") {
        return (
          value &&
          [fileFormats.JPEG, fileFormats.JPG, fileFormats.PNG].includes(
            (value as File).type
          )
        );
      }
      return true;
    })
    .test("fileSize", "FILE_SIZE_TOO_LARGE", (value) => {
      {
        if (typeof value !== "string") {
          return value && (value as File).size <= config.MAX_IMAGE_SIZE_LOGO;
        }
        return true;
      }
    }),
});
