export enum ROUTES {
  LOGIN = 'login',
  CHANGE_PASSWORD = 'change-password',
  DASHBOARD = 'dashboard',
  RETAILERS = 'retailers',
  ADD_RETAILERS = 'add-retailers',
  EDIT_RETAILERS = 'edit-retailers',
  RESET_PASSWORD = 'reset-password',
  FORGOT_PASSWORD = 'forgot-password',
  INTERNAL_SERVER_ERROR = 'internal-server-error',
  NOT_FOUND = '*',
}
