/**
 * @desc: to set the favicon of the page dynamically
 * @param url
 */
export const setFavicon = (url: string) => {
  let link =
    (document.querySelector("link[rel*='icon']") as HTMLLinkElement) ||
    document.createElement('link');
  link.type = 'image/x-icon';
  link.rel = 'shortcut icon';
  link.href = url;
  document.getElementsByTagName('head')[0].appendChild(link);
};
