import { FC, ReactNode } from 'react';
import { Outlet } from 'react-router-dom';

import { Box } from '@mui/material';
import PublicRoutes from 'components/authentication/PublicRoutes';

interface BaseLayoutProps {
    children?: ReactNode;
}

const BaseLayout: FC<BaseLayoutProps> = () => {
    return (
        <PublicRoutes>
            <Box
                sx={{
                    flex: 1,
                    height: '100%'
                }}
            >
                <Outlet />
            </Box>
        </PublicRoutes>
    );
};

export default BaseLayout;
