import { Language } from '@mui/icons-material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getKey } from 'utils/local-storage';

const LanguageSelector = () => {
    const { i18n } = useTranslation();

    const handleLanguageChange = (event) => {
        const selectedLanguage = event.target.value;
        i18n.changeLanguage(selectedLanguage);
    };

    useEffect(() => {
        const language = getKey('i18nextLng');
        i18n.changeLanguage(language);
    }, []);

    return (
        <div className="lang-dropdown">
            <Language />
            <select
                id="pageLimit"
                onChange={handleLanguageChange}
                value={getKey('i18nextLng')}
            >
                <option value="en">English</option>
                <option value="de">German</option>
            </select>
        </div>
    );
};

export default LanguageSelector;
