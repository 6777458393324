import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import SidebarLayout from 'layouts/SidebarLayout';
import AddRetailers from 'components/retailersList/AddRetailer';
import EditRetailers from 'components/retailersList/EditRetailer';
import { ROUTES } from 'constants/routes';

const Retailers = lazy(() => import('components/retailersList'));

const dashboardRoutes = [
  {
    path: `/${ROUTES.DASHBOARD}`,
    element: <SidebarLayout />,

    children: [
      {
        path: '',
        element: <Navigate to={ROUTES.RETAILERS} replace />,
      },
      {
        path: `${ROUTES.RETAILERS}`,
        element: <Retailers />,
      },
      {
        path: ROUTES.ADD_RETAILERS,
        element: <AddRetailers />,
      },
      {
        path: `${ROUTES.EDIT_RETAILERS}/:id`,
        element: <EditRetailers />,
      },
    ],
  },
];

export default dashboardRoutes;
