import { Form, Formik, FormikErrors } from 'formik';
import { useNavigate } from 'react-router';
import { Box, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useEffect, useRef, useState } from 'react';
import Button from 'components/UI/Button';
import LoadingButton from 'components/UI/LoadingButton';
import InputField from 'components/UI/form/InputField';
import { ROUTES } from 'constants/routes';
import useTranslate from 'hooks/useTranslate';
import { useAppDispatch, useAppSelector } from 'redux/redux';
import { showSuccess } from 'utils/toast-alerts';
import { EditRetailerSchema } from 'utils/validation-schema';
import { Retailer } from 'models/retailer-interface';
import { retailerData, selectLoading } from 'redux/retailers/selector';
// import ColorPickerField from "components/UI/form/ColorPickerField";
import FileInputField from 'components/UI/form/FileInputField';
import { updateRetailerAsync } from 'redux/retailers/thunk';

type stateData = {
  showPicker?: boolean;
  showFileInput?: boolean;
};

/**
 * @desc: edit retailers form
 * @returns
 */
const EditRetailers = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { retailerDetail } = useAppSelector(retailerData);
  const [state, setState] = useState<stateData>({
    showPicker: false,
    showFileInput: false,
  });
  const popoverRef = useRef(null);

  const { parseUI } = useTranslate();

  const loading = useAppSelector(selectLoading);
  const { showPicker, showFileInput } = state;

  /**
   * @desc:common update state method
   */
  const updateState = (_data: stateData) => {
    setState((prevState) => ({ ...prevState, ..._data }));
  };

  /**
   * @desc: to update the state of color picker ref
   * @param event
   */
  const handleClickOutside = (event) => {
    if (
      popoverRef.current &&
      !popoverRef.current.contains(event.target as Node)
    ) {
      updateState({ showPicker: false });
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  /**
   * @desc: to create form data
   * @param values
   * @returns
   */
  const createFormData = (values: Retailer) => {
    const formData = new FormData();

    /** to append only those data to formData, which values are changed */
    Object.keys(values).forEach((key) => {
      if (key !== '_id' && values[key] !== retailerDetail[key]) {
        if (key === 'logo' && typeof values[key] === 'string') {
          // If the logo field is a string, it means the logo has not been changed
          // and should not be included in the FormData object.
          return;
        }
        formData.append(key, values[key]);
      }
    });
    return formData;
  };

  /**
   * @desc: to handle submit of edit form
   * @param values
   */
  const handleSubmit = async (values: Retailer) => {
    const formData = createFormData(values);

    /** to trigger update retailers API */
    try {
      const response = await dispatch(
        updateRetailerAsync({
          retailerId: retailerDetail.slug,
          data: formData,
        })
      ).unwrap();

      /** show success toast */
      showSuccess(response.data.message);
      navigate(`/${ROUTES.DASHBOARD}`);
    } catch (error) {
      navigate(`/${ROUTES.DASHBOARD}`);
    }
  };

  /**
   * @desc: on cancel click
   */
  const onCancelClick = () => {
    navigate(`/${ROUTES.DASHBOARD}`);
  };

  /**
   * @desc: on change field of logo
   * @param event
   * @param setFieldValue
   * @param setFieldError
   */
  const handleLogoChange = async () => {
    updateState({
      showFileInput: false,
    });
  };

  /**
   * @desc: to render logo in case of edit
   * @param values
   * @param setFieldValue
   * @returns
   */
  const renderLogoField = (
    values: Retailer,
    setFieldValue: {
      (
        field: string,
        value: any,
        shouldValidate?: boolean
      ): Promise<void | FormikErrors<Retailer>>;
      (arg0: string, arg1: File): void;
    }
  ) => {
    /** if logo is present(URL) */
    if (values.logo && !showFileInput) {
      return (
        <>
          <p className="edit-reatiler-logo-text">
            {parseUI('LOGO')}
            <sup>*</sup>
          </p>
          <Box display="flex" alignItems="center">
            <div className="logo-retailers-edit">
              <img
                src={
                  typeof values.logo === 'string'
                    ? values.logo
                    : URL.createObjectURL(values.logo)
                }
                alt="Retailer Logo"
              />
            </div>
            <IconButton
              onClick={() => {
                setFieldValue('logo', undefined);
                updateState({ showFileInput: true });
              }}
              style={{ marginLeft: '10px' }}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        </>
      );
    } else if (showFileInput) {
      return <FileInputField name="logo" onChange={handleLogoChange} />;
    } else {
      return null;
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      className="inner-box-wrapper plr36"
      style={{
        paddingTop: 30,
        paddingBottom: 37,
        paddingLeft: 15,
        paddingRight: 15,
      }}
    >
      <h1>{parseUI('EDIT_RETAILER')}</h1>
      <Formik
        initialValues={retailerDetail}
        validationSchema={EditRetailerSchema}
        onSubmit={handleSubmit}
      >
        {({ values, isValid, dirty, setFieldValue }) => (
          <Form className="login-outer change-pass-form">
            <InputField
              name={'name'}
              type={'text'}
              label={'RETAILER_NAME'}
              required
            />
            <InputField name={'place'} type={'text'} label={'PLACE'} required />
            {/* <ColorPickerField
              name="color"
              showPicker={showPicker}
              setShowPicker={(value: boolean) =>
                updateState({ showPicker: value })
              }
              popoverRef={popoverRef}
            /> */}
            {/* to display retailer logo */}
            {renderLogoField(values, setFieldValue)}

            {/* to display update button */}
            <div className="btn-end">
              <LoadingButton
                type="submit"
                label="UPDATE"
                loader={loading}
                className="loading-button"
                disabled={!isValid || !dirty || loading}
              />

              {/* cancel button */}
              <Button type="button" onClick={onCancelClick} label="CANCEL" />
            </div>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default EditRetailers;
