import ReactDOM from "react-dom";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { persistorStore, store } from "./redux/store";
import * as serviceWorker from "serviceWorker";
import App from "App";
import "./i18n/config";
import { ToastContainer } from "react-toastify";
import "nprogress/nprogress.css";
import "react-toastify/dist/ReactToastify.css";
import { PersistGate } from "redux-persist/integration/react";
import "./styles/global.css";
import ErrorBoundary from "components/errorBoundary/ErrorBoundary";
ReactDOM.render(
  <HelmetProvider>
    <ErrorBoundary>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistorStore}>
          <BrowserRouter>
            <App />
            <ToastContainer />
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </ErrorBoundary>
  </HelmetProvider>,
  document.getElementById("root")
);

serviceWorker.unregister();
