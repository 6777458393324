import { TextField } from "@mui/material";
import { useField } from "formik";
import { ChangeEvent, FC } from "react";
import useTranslate from "hooks/useTranslate";
import { fileValidation } from "utils/validation-schema";

type FileInputFieldProps = {
  name: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

/**
 * @desc: file input component
 * @param param0
 * @returns
 */
const FileInputField: FC<FileInputFieldProps> = ({ name, onChange }) => {
  const [field, meta, helpers] = useField(name);
  const { setValue, setError } = helpers;
  const { value, ...fieldWithoutValue } = field;
  const { parseUI, parseError } = useTranslate();

  /**
   * @desc: to validate file on change as well
   * @param file
   * @returns
   */
  const validateFileOnChange = async (file: File) => {
    try {
      /** Validate the file using the logoFileSchema */
      await fileValidation.validate(file);

      return true;
    } catch (error) {
      /** in case of error, display field error */
      setError(error.message);

      return false;
    }
  };

  /**
   * @desc: on change of file input
   * @param event
   */
  const onChangeFileInput = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = (event.currentTarget as HTMLInputElement)
      .files[0]; /** If the file is valid, set the field value */
    setValue(file);
    /** to validate the file */
    const isValid = await validateFileOnChange(file);

    if (!isValid) return;

    if (onChange) onChange(event);
  };

  return (
    <TextField
      {...fieldWithoutValue} // Spread field without value
      required
      className="logo-update"
      type="file"
      fullWidth
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{ style: { color: "rgba(34, 51, 84, 0.7)" } }}
      onChange={onChangeFileInput}
      label={parseUI("LOGO")}
      error={meta.touched && !!meta.error}
      helperText={meta.touched && meta.error ? parseError(meta.error) : ""}
    />
  );
};

export default FileInputField;
