import { ErrorResponseStack } from "models/http-interface";

/**
 * The function `formatError` takes in an error payload and returns a formatted error message.
 * @param {string | ErrorResponseStack[]} errorPayload - The `errorPayload` parameter can be either a
 * string or an array of `ErrorResponseStack` objects.
 * @returns The function `formatError` returns either a string or the first error message in the
 * `errorPayload` array.
 */
export const formatError = (errorPayload: string | ErrorResponseStack[]) => {
  if (typeof errorPayload === "string") {
    return errorPayload;
  } else {
    if (!Array.isArray(errorPayload)) {
      return "Something went wrong!";
    }
    return errorPayload[0].message && Array.isArray(errorPayload[0].message)
      ? errorPayload[0].message[0]
      : "Something went wrong!";
  }
};
